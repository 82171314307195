import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Card, Row, Col, Table, Nav, Tab, Form, Button } from 'react-bootstrap';
const PhoneNumberInput = ({ defaultValue = '', provider, onChange }) => {
  const [value, setValue] = useState(defaultValue);
  
  const formatPhoneNumber = (phoneNumber) => {
    // Implémentez la logique de formatage du numéro de téléphone ici
    // Par exemple, ajoutez des espaces, des tirets, etc.
    return phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
  };

  const handleChange = (event) => {
    console.log('event.target.value vaut : ',event.target.value)
    let value = event.target.value.replace(/[^\d]+/g,'');
    
    // Supprimez tous les caractères non numériques
    const formattedValue = value.slice(0,10);

    if (onChange) onChange({ target: { name: 'phoneNumber', value: formattedValue } });
    setValue(formattedValue);
  };

  useEffect(() => {
    if (defaultValue) setValue(formatPhoneNumber(defaultValue));
  }, [defaultValue]);

  return (
     <InputMask className="form-control"  placeholder={"Numéro "+ provider + " Money"} mask="99 99 99 99 99" value={value} onChange={handleChange} />
    // <Form.Control mask="99 99 99 99 99" name = 'cardNumber' placeholder="Numéro mobile Money" value={value}  onChange={handleChange} />
  );
};

export default PhoneNumberInput;
