import React, { useState, useEffect } from 'react';
import PhoneNumberInput from '../directives/PhoneNumberInput';
import {  Card, Row, Form, Button } from 'react-bootstrap';
import logoMoov  from '../../img/moov-logo.png'
import logoMtn  from '../../img/mtn-logo.png'
import logoOrange  from '../../img/orange-logo.png'
import './PaymentMobileMoneyForm.css';
import 'react-tabs/style/react-tabs.css';

const API_URL = '/';//'http://192.168.1.5:3000/'; api.librepay.net

async function fetchWithTimeout(url, options, timeout = 40000) {

   console.log('fetchWithTimeout -- url vaut ',url);

      //  console.log('fetchWithTimeout -- Ok_2');
  return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Le serveur met du temps à répondre. Si vous venez d\' éffectuer une opération, veuillez consulter son status dans le menu "Transactions".')), timeout)
      ),
  ]);
}

async function requestPost(route, postData) {

  try {
      const response = await fetchWithTimeout(`${API_URL}${route}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      });
      console.log('requestPost -- ok1');

      // Nouveau code pour afficher la réponse brute
      const rawResponse = await response.text();
      console.log('Raw Response: ', rawResponse);

      if (!response.ok) {
          let errorData;

          try {
              errorData = JSON.parse(rawResponse);
          } catch(e) {
              console.error("Failed to parse error response as JSON: ", e);
              throw new Error("Échec de la connexion");
          }

          return errorData;
      }

      let data;

      try {
          data = JSON.parse(rawResponse);
      } catch(e) {
          console.error("Failed to parse response as JSON: ", e);
          throw new Error("Échec de la connexion");
      }

      return data;

  } catch (error) {
      console.log('requestPost -- error');
      if (error.message.includes('Network request failed')){
          throw new Error("Service temporairement indisponible. Veuillez reprendre un peu plutard.");
      }
      throw error;
  }
}

const PaymentMobileMoneyForm = ({ paymentData, token, payLinkId, onResult }) => {
    const [mobilemoney, setMobilemoney] = useState({
        network: 'orange_ci',
        phoneNumber: '',
        token: '',
    });
    const [isFormValid, setIsFormValid] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const checkFormValidity = () => {
        // implémentez la logique de validation ici
        // mettez à jour setIsFormValid en conséquence
        setIsFormValid(false)
        if (mobilemoney.phoneNumber.length === 10){
            setIsFormValid(true)
        }
    };

    function formatAmount(amount) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(amount);
    }

    const sendFormMobileMoney = async (event) => {
        event.preventDefault();
        // implémentez la logique d'envoi du formulaire ici
        try {
            setIsLoading(true)

            const payData = { 
                // transactionId: , 
                payLinkId, 
                providerName: mobilemoney.network, 
                phoneNumber: mobilemoney.phoneNumber, 
                amount: paymentData.amount, 
                currency: 'XOF', 
                paymentMode: 'mobile_money' 
            }
            const response = await requestPost(`api/merchant/confirm-web-payment/?token=${token}`, payData); //fetch(`${API_URL}/update-transfer-account`, { accountProvider, accountNumber, otp }, { headers: { 'Authorization': `Bearer ${token}` } });
            onResult(response)
            
        } catch (error) {
            const result = {
                success: false,
                message: error.message
            }
            onResult(result)
        }finally{
            setIsLoading(false)
        }
    };

    const handleInputChange = (event) => {
        
        setMobilemoney({
            ...mobilemoney,
            [event.target.name]: event.target.value,
        });
        // checkFormValidity();
        
    };

    const handleNetworkChange = (network) => {
        
        setMobilemoney({
            ...mobilemoney,
            network,
        });
        // checkFormValidity();
    };

    useEffect(() => {
        // console.log('mobilemoney vaut: ',mobilemoney)
        checkFormValidity();
    }, [mobilemoney]);

    return (
        <Form  name="paymentMobileMoneyForm" className="payment-MobileMoney-form" onSubmit={sendFormMobileMoney}> 
            {/* <div className=" "> */}
            <Row className="mb-3 justify-content-center">
                {/* <Col > */}
                    <Card className={mobilemoney.network === 'orange_ci' ? "card-avatar-active" : "card-avatar"} onClick={() => handleNetworkChange('orange_ci')}>
                        <Card.Body className="text-center p-0">
                            <img  src={logoOrange} alt="Orange logo" className="img-fluid"/>
                            {/* <Card.Title>Orange</Card.Title> */}
                        </Card.Body>
                    </Card>
                {/* </Col> */}
                {/* <Col > */}
                    <Card className={mobilemoney.network === 'moov_ci' ? "card-avatar-active" : "card-avatar"} onClick={() => handleNetworkChange('moov_ci')}>
                        <Card.Body className="text-center p-0 ">
                            <img  src={logoMoov} alt="Moov logo" className="img-fluid"/>
                            {/* <Card.Title>Moov</Card.Title> */}
                        </Card.Body>
                    </Card>
                {/* </Col> */}
                {/* <Col > */}
                    <Card className={mobilemoney.network === 'mtn_ci' ? "card-avatar-active" : "card-avatar"} onClick={() => handleNetworkChange('mtn_ci')}>
                        <Card.Body className="text-center p-0">
                            <img  src={logoMtn} alt="Mtn logo" className="img-fluid"/>
                            {/* <Card.Title>MTN</Card.Title> */}
                        </Card.Body>
                    </Card>
                {/* </Col> */}
            </Row>
            <Form.Group controlId="formPhoneNumber">
            {/* <Form.Label>Numéro de téléphone</Form.Label> */}
            <PhoneNumberInput onChange={handleInputChange} provider={mobilemoney.network} />
            </Form.Group><br/>

            {mobilemoney.network === 'orange_ci' && (
                <></>
                // <Form.Group controlId="formToken">
                //     {/* <Form.Label>Token</Form.Label> */}
                //     <Form.Control type="text" 
                //                 placeholder="Token" 
                //                 value={mobilemoney.token} 
                //                 onChange={handleInputChange} 
                //                 name="token" />
                // </Form.Group>
            )}
            {!isLoading && (
            <Button id="btn-form-mobile-money" 
                    className="payment-form__button" 
                    disabled={!isFormValid}
                    type="submit"
                    // onClick={sendFormMobileMoney} 
                    aria-label="Payer">
                Payer ( {formatAmount(paymentData.amount)} ) {/* assuming formatAmount is a function defined elsewhere */}
            </Button>
            )}
            {isLoading && (
            <Button id="btn-form-mobile-money" 
                    className="payment-form__button" 
                    type="button" 
                    disabled 
                    >
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Loading...</span>
            </Button>
            )}
            {/* </div> */}
        </Form>
    );
};

export default PaymentMobileMoneyForm;
