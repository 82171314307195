 import logo from './logo-librepay.png'
// import './/css/style.css'
import './App.css';
import React, { useEffect, useState } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // This will include the required styles

import { Card, Row} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

// import PhoneNumberInput from './js/directives/PhoneNumberInput';
import CardForm from './js/controllers/PaymentCardForm';
import PaymentMobileMoneyForm from './js/controllers/PaymentMobileMoneyForm';

function App() {

  // const [transaction, setTransaction] = useState(null);
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [success, setSuccess] = useState(null);

  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenTemp = urlParams.get('token');
    setToken(tokenTemp);
    // const data = {
    //     "transactionId": "REF0024434",
    //     "amount": "5000",
    //     "currency": "XOF",
    //     "description": "Reglement Livraison"
    // };

    // setTransaction(data);

    fetch(`/verify-token-paiement?token=${tokenTemp}`)
    // fetch(`/verify-token-paiement?token=${tokenTemp}`)
    .then(response => {
      if (!response.ok) {
        // throw new Error('Erreur lors de la récupération des données de transaction.');
        throw new Error('Désolé, la page que vous recherchez est introuvable.');
      }
      return response.json();
    })
    .then(data => setPaymentData(data))
    .catch(error => {
      console.error(error);
      setError(error.message);
    });

  }, []);

  function formatAmount(amount) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(amount);
  }

  function handleResult(response){
    if (response.success === null ) 
      setError2("Session expirée");
    
    if (!response.success){
        setError2(response.message);
    }

    if (response.success){
      setSuccess(response.message)
    }
    
  }

  if (error) {
    return (
      <div className="App d-flex justify-content-center">
      <Card  style={{ width: '35rem' }}>
        {/* <Card.Header>
          <img src={logo} style={{ width: '100px', height: '50px' }} alt="Logo" className="float-left" />
        </Card.Header> */}
        <Card.Body className="alert-danger">
        <img src={logo} style={{ width: '100px', height: '50px' }} alt="Logo" className="float-left" />
          <Card.Title>404 - Page non trouvée</Card.Title>
          <Card.Text>{error}</Card.Text>
        </Card.Body>
      </Card>
    </div>
    );
  }

  if (!paymentData) {
    // return <div>Loading...</div>;
    return (
      <div className="App d-flex justify-content-center">
      <Card  style={{ width: '35rem' }}>
        {/* <Card.Header>
          <img src={logo} style={{ width: '100px', height: '50px' }} alt="Logo" className="float-left" />
        </Card.Header> */}
        <Card.Body >
          
          <Card.Title>
          <img src={logo} style={{ width: '100px', height: '50px' }} alt="Logo" className="float-left" />
          </Card.Title>
          <Card.Text>
          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Loading...</span>  
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
    );
  }

  if (paymentData) {
    return (
      <div className="App d-flex justify-content-center align-items-center vh-100">
        <Card style={{ width: '35rem' }}>
          <Card.Header>
            <img src={logo} style={{width: '100px', height: '50px'}} alt="Logo" className="float-left" />
            <div className="float-right">
              <h5>{formatAmount(paymentData.amount)}</h5>
              {paymentData.description}
            </div>
          </Card.Header>
          <Card.Body >
            <Row className="mb-2">
            <div className="notification">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAAAXNSR0IArs4c6QAABJdJREFUWAnNWN1rHFUUP/fuuJsQP2qatrhQxCbs5rFaFezLtn4QLRWFCtIq/gXNqiT1wY8qVPtgEwq7/Q/UBsGID9IaqSbrQ9GH4osP2ZhYSzEQP8BUajbr7lx/vzs742Y6003KLmYgmbnnnnN+vznn3nPPrJINXDumTvXc2ZUa0qIOiJJ+JZIWo9LWhTKLRmRRjCy4Ys5dq6xOLQ0du75e9/DV+hooFe53RL1lRD2llHS1thAxRipKzPmamBPzufz3rWxuSuS+r8buTTpd7+Htj0BRGTEubheVcT8TV12q1tXiX4nfFwlyR70vnUyYtGizxyj9LKjsVaI0ogQzOVutVd64/NjolThCsUQyM0W8vZpABO7i24FGobZSGV8YOvZrnLNmef/Uqe1Od9cIUpdnFOFj2RhzeG7f8PlmPf85kshgqTBilHrfvpExkzWpvrqQG7nqG23k3l8a3+lI8jRe6hAjqox5bTaXHw/7uIEISSCiYwypct03Z/flT4aNbmU8OFN43Wj9LgCxdNzRMJk1RGw6tHwOXRBHGHPDH98KaJxNplR8nunmUsZqO9icJu0bcWHaNYFwMBLtJkEc+qRvppxYxPTxHf+Bu8NbmGayvI50DHxdyDmOGsUi3I0AXkcIvzP1+snyo6+UfZ9Rd6Y6Wyo+wDVjd6TIi9SzEWGd4Bbl7uDCjHLQLIOjlx1HzyCFB5HbBCK9A2ReUonED9npwnPNulHPxPB2ohyx2FCyRBJKHbeLCFu01e7IXDi9C7pjAF+q1d1HZnPD6XJuuLdu3ENGyd+i9QfZL4tetY1iAZnFABYxWSipplm2sT+e5NZinYixDcTqNmc/3t5xRZ2Y35//tjFhfszlP4Xft23NSKknAoOYB2IRk9WaHDTPDhrDycX1FCvUl+3Wt2t+CWOYuvnZyhTOoBaXh4UqDWx7ftkDDEa2bLcwbve0j0kO+JN+C4Czo91ALf35mDzJB78pYrupTLUq2Z8ePzoXZ7xt+p3be3XfWVTcnShISdSCCnTrIf0E8s40V7EQr/5ZWTl8s1Zg14UzmWRSgG/mHNtPwMo/RUOOg2Gvuvsh5PNpqOLy/geTTQ8g6I92b0klH14SmfYF4Tsxt0ofD5N0UFl7r3UHHsIGHKMkB7pR81GyVjbNmFgjxvYT//T03BPlrJOyABMc2LhYIrap6SRqhG8fkxw0e0yrg84qQrezIi0PWgBw0Gx0OfDau87ihr2jOD5DGTlodtv2AEKPyfYurNypsYdl9hKbHLTd58p8wR7B9phxyK57GQddLW76Bjl1aRNzEYuY7PTJwW5ZHsUJpS9hvazWZDUTdwIPnCts091qa4zvNWJ3xfwxfyD/2xphY+D1sak5lJwUTu09/NwIake2dOZDFKwX0MRN4lhv2VNEAaxXhn7mEzZGSMtH5dzR/xojOuB3ByaWqcBGd71ON6pH3w0Sy8T07YOIULApmmcSYVfN7w6wA2k10c7INCIxYX0Do7mDJ/aaiFDAa1N8YHlUGmn6vz85fTKb4iPcJ8O7V2fUcTbZXn/bPBv9jB3Yvp8lwhCd/KHmXykoU2GwolhLAAAAAElFTkSuQmCC"  
              className="notification__icon d-flex float-left "
              alt=''
              />
              <span className="notification__text">

                Nous ne stockons pas vos données de paiement, mais les transférons immédiatement via un canal crypté au système de paiement
              </span>
            </div>
            </Row>
            {/* <Row className="mb-4"> */}
            <div >
            <Card className={error2? "card-form alert-danger": (success ? "card-form alert-success" : "card-form")}>
            {!error2 && !success && (<Card.Body>
            <Tabs >
                <TabList>
                  {/* <Tab >Carte bancaire</Tab> */}
                  <Tab >Mobile Money</Tab>
                    {/* <div className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                        <a className="nav-link active" id="pills-card-tab" data-bs-toggle="pill" href="#pills-card" role="tab" aria-controls="pills-card" aria-selected="true">Carte bancaire</a>
                        <a className="nav-link" id="pills-mobile-money-tab" data-bs-toggle="pill" href="#pills-mobile-money" role="tab" aria-controls="pills-mobile-money" aria-selected="false">Mobile money</a>
                    </div> */}
                </TabList>
              
                {/* <TabPanel >
                  <CardForm paymentData={paymentData} onResult={handleResult}/>
                </TabPanel> */}
          
                <TabPanel >
                <PaymentMobileMoneyForm paymentData={paymentData} token={token} payLinkId={paymentData.id} onResult={handleResult}/>
              </TabPanel>
                {/* <div className="payment-form__agreement">
                  En cliquant sur le bouton "Payer", vous consentez aux paiements d'abonnement récurrents. Vous pouvez toujours annuler votre abonnement dans les paramètres du service.
              </div> */}
            </Tabs>
          </Card.Body> )}
          {error2 && (
            <Card.Body>
              <Card.Title>Erreur</Card.Title>
              <Card.Text>{error2}</Card.Text>
            </Card.Body>
          )}
          {success && (
            <Card.Body>
              <Card.Title>Paiement initié</Card.Title>
              <Card.Text>{success}</Card.Text>
            </Card.Body>
          )}
        </Card>
     </div>
            {/* </Row> */}
            
          </Card.Body>
          {/* <Card.Footer className="text-center">
            <Button variant="primary">Payer</Button>
          </Card.Footer> */}
        </Card>
        
      </div>
    );
  }
}

// const tabStyle = {
//   color: '#007BFF',  // Ajoutez la couleur de texte que vous voulez ici
//   backgroundColor: '#FFFFFF',  // Ajoutez la couleur de fond que vous voulez ici
//   borderColor: '#000000',  // Ajoutez la couleur de bordure que vous voulez ici
//   // height: '50px',
//   // alignSelf: 'flex-start',
// };



// const tabPanelStyle = {
//   height: '300px',
// };

export default App;
